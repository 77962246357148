




































































































































































import { Vue, Component } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import { GridReadyEvent, ICellRendererParams } from '@ag-grid-community/core';
import { AgGridVue } from '@ag-grid-community/vue';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import B2OrderAuditService from '@/services/b2/order-audit.service';
import B2OrderAuditModel from '@/models/b2b/order-audit.model';
import { IGridConfig } from '@/interfaces/grid-config.interface';
import AgGridWrapper from '@/components/ag-grid-wrapper.vue';

type StoreRowsConfig = {
  loja: string | undefined;
  dav: number | undefined;
  totalPecas: number | undefined;
  codReferencia: string | undefined;
  codTamanho: string | undefined;
  seqSortimento: string | undefined;
  codSKU: string | undefined;
  nomeReferencia: string | undefined;
  qtdePedida: number | undefined;
  qtdeAlocada: number | undefined;
  qtdeFaltante: number | undefined;
  pedidosLoja: string | undefined;
};

type AuditMessageRowsConfig = {
  id: string | undefined;
  message: string | undefined;
};

@Component({
  components: {
    AgGridWrapper,
    AgGridVue,
  },
})
export default class B2BOrderAudit extends Vue {
  @inject(InjectionIdEnum.B2OrderAuditService)
  private auditService!: B2OrderAuditService;

  tab: string | null = null;

  model: B2OrderAuditModel = plainToClass(B2OrderAuditModel, {});

  grid: GridReadyEvent | null = null;

  gridStores: GridReadyEvent | null = null;

  gridHistory: GridReadyEvent | null = null;

  gridSettingsAlocation: IGridConfig = {
    loading: false,
    defaultSort: [{ colId: 'codReferencia', sort: 'asc' }],
    columnDefs: [],
    itemsPerPage: 8,
  };

  gridSettingsStores: IGridConfig = {
    loading: false,
    defaultSort: [{ colId: 'codLoja', sort: 'asc' }],
    columnDefs: [],
    itemsPerPage: 8,
  };

  gridSettingsAudit: IGridConfig = {
    loading: false,
    defaultSort: [{ colId: 'id', sort: 'asc' }],
    columnDefs: [],
    itemsPerPage: 8,
  };

  storeData: StoreRowsConfig[] = [];

  auditMessagesData: AuditMessageRowsConfig[] = [];

  private prepareAlocationColumnDefs(): void {
    const columnDefs = [
      {
        headerName: `${this.$t('b2.audit.productCode')}`,
        field: 'codReferencia',
        minWidth: 140,
        maxWidth: 140,
        rowGroup: true,
      },
      {
        headerName: `${this.$t('b2.audit.skuCode')}`,
        field: 'codSKU',
        minWidth: 85,
        maxWidth: 85,
      },
      {
        headerName: `${this.$t('b2.audit.sizeCode')}`,
        field: 'codTamanho',
        minWidth: 110,
        maxWidth: 110,
      },
      {
        headerName: `${this.$t('b2.audit.storeOrders')}`,
        field: 'pedidosLoja',
      },
      {
        headerName: `${this.$t('b2.audit.quantityAlocated')}`,
        field: 'qtdeAlocada',
        minWidth: 130,
        maxWidth: 130,
      },
      {
        headerName: `${this.$t('b2.audit.quantityMissing')}`,
        field: 'qtdeFaltante',
        minWidth: 130,
        maxWidth: 130,
      },
      {
        headerName: `${this.$t('b2.audit.quantityDemanded')}`,
        field: 'qtdePedida',
        minWidth: 130,
        maxWidth: 130,
      },
      {
        headerName: `${this.$t('b2.audit.color')}`,
        field: 'seqSortimento',
        minWidth: 100,
        maxWidth: 100,
      },
    ];
    this.gridSettingsAlocation.columnDefs = columnDefs;
  }

  private prepareStoreRows(): void {
    this.model.lojas?.forEach((store) => {
      store.itens?.forEach((item) => {
        const row: StoreRowsConfig = {
          loja: store.nomeLoja,
          dav: store.dav,
          totalPecas: store.totalPecas,
          codReferencia: item.codReferencia,
          codTamanho: item.codTamanho,
          seqSortimento: item.seqSortimento,
          codSKU: item.codSKU,
          nomeReferencia: item.nomeReferencia,
          qtdePedida: item.qtdePedida,
          qtdeAlocada: item.qtdeAlocada,
          qtdeFaltante: item.qtdeFaltante,
          pedidosLoja: item.pedidosLoja,
        };

        this.storeData.push(row);
      });
    });

    const columnDefs = [
      {
        headerName: `${this.$t('b2.audit.store')}`,
        field: 'loja',
        minWidth: 200,
        maxWidth: 200,
        rowGroup: true,
      },
      {
        headerName: `${this.$t('b2.audit.totalPieces')}`,
        field: 'totalPecas',
        minWidth: 110,
        maxWidth: 110,
      },
      {
        headerName: 'DAV',
        field: 'dav',
        minWidth: 85,
        maxWidth: 85,
        rowGroup: true,
      },
      {
        headerName: `${this.$t('b2.audit.productCode')}`,
        field: 'codReferencia',
        minWidth: 140,
        maxWidth: 140,
      },
      {
        headerName: `${this.$t('b2.audit.sizeCode')}`,
        field: 'codTamanho',
        minWidth: 110,
        maxWidth: 110,
      },
      {
        headerName: `${this.$t('b2.audit.color')}`,
        field: 'seqSortimento',
        minWidth: 100,
        maxWidth: 100,
      },
      {
        headerName: `${this.$t('b2.audit.skuCode')}`,
        field: 'codSKU',
        minWidth: 85,
        maxWidth: 85,
      },
      {
        headerName: `${this.$t('b2.audit.productName')}`,
        field: 'nomeReferencia',
      },
      {
        headerName: `${this.$t('b2.audit.quantityAlocated')}`,
        field: 'qtdeAlocada',
        minWidth: 130,
        maxWidth: 130,
      },
    ];
    this.gridSettingsStores.columnDefs = columnDefs;
  }

  private prepareAuditMessageRows(): void {
    this.model.auditoria?.forEach((audit) => {
      audit.mensagens?.forEach((message) => {
        const row: AuditMessageRowsConfig = {
          id: `${audit.inicio} | ${audit.fim} | ${audit.resultado}`,
          message,
        };

        this.auditMessagesData.push(row);
      });
    });
    const columnDefs = [
      {
        headerName: `${this.$t('b2.audit.information')}`,
        field: 'id',
        minWidth: 250,
        maxWidth: 250,
        rowGroup: true,
        hide: true,
      },
      {
        headerName: `${this.$t('b2.audit.message')}`,
        field: 'message',
        cellRenderer: (params: ICellRendererParams): string => {
          if (params.value.includes('ERROR #11000')) {
            return `<span style="color: red; font-weight: bold">${params.value}</span>`;
          }
          return params.value;
        },
      },
    ];
    this.gridSettingsAudit.columnDefs = columnDefs;
  }

  async mounted(): Promise<void> {
    const loader = this.$loading.show();
    try {
      const response = await this.auditService.getAuditInformation();
      if (response.hasError) {
        this.$notify.error(response.customMessageError);
      }

      this.model = response.result;
      this.prepareStoreRows();
      this.prepareAlocationColumnDefs();
      this.prepareAuditMessageRows();
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
  }

  onExport(type: string): void {
    let gridApi: GridReadyEvent | null = null;
    if (type === 'alocation') {
      gridApi = this.grid;
    } else if (type === 'stores') {
      gridApi = this.gridStores;
    } else {
      gridApi = this.gridHistory;
    }

    if (gridApi) {
      gridApi.api.exportDataAsExcel({
        allColumns: true,
        author: 'IBtech',
        sheetName: 'Alocação',
        fileName: new Date().toString(),
      });
    }
  }
}
